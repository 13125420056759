import React from 'react';

// import Card from './Card.js';

const PinterestLayout: React.FC = (props) => {
  return <div style={styles.pin_container}>{props.children}</div>;
  // return <div style={styles.pin_container}></div>;
};

const styles = {
  pin_container: {
    margin: '0',
    padding: '0',
    width: '80vw',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 250px)',
    gridAutoRows: '10px',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    justifyContent: 'center',
    backgroundColor: 'black'
  } as React.CSSProperties
};

export default PinterestLayout;
