import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase/compat';
import { auth } from '../firebase';

const AuthContext = React.createContext<{
  currentUser: firebase.User | null,
  isAuthReady: boolean,
  logout: () => void,
  refreshToken: () => void,
}>({
  currentUser: null,
  isAuthReady: false,
  logout: () => {
  },
  refreshToken: () => {
  },
});

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: any) {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);
  const [isAuthReady, setIsAuthReady] = useState<boolean>(false);

  function logout() {
    return auth.signOut();
  }

  async function refreshToken() {
    if (currentUser) {
      await currentUser?.getIdToken(true);
    }
  }

  useEffect(() => {
    return auth.onAuthStateChanged(async user => {
      if (!user) {
        localStorage.removeItem('fbToken');
      } else {
        const token = await user?.getIdToken() as string;
        localStorage.setItem('fbToken', token);
      }
      setIsAuthReady(true);
      setCurrentUser(user);
    });
  }, []);

  useEffect(() => {
    return auth.onIdTokenChanged(async user => {
      if (user) {
        const token = await user?.getIdToken() as string;
        localStorage.setItem('fbToken', token);
        setCurrentUser(user);
        setIsAuthReady(true);
      }
    });
  }, []);
  return (
    <AuthContext.Provider value={{ currentUser, isAuthReady, logout, refreshToken }}>
      {children}
    </AuthContext.Provider>
  );
}
