import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const MODE: string = process.env.NODE_ENV || 'development';
const authDomainMap: { [key: string]: string } = {
  development: 'lonlai-efe76.firebaseapp.com',
  production: 'lonlai-c.haoweicrushu.com',
};

const app = firebase.initializeApp({
  apiKey: "AIzaSyC6ZISRAUyzh2kQZMNZVzATZGE2rbs8e7o",
  authDomain: authDomainMap[MODE as string],
  projectId: "lonlai-efe76",
  storageBucket: "lonlai-efe76.appspot.com",
  messagingSenderId: "611633444750",
  appId: "1:611633444750:web:433b4dfdc96b3216a756df",
  measurementId: "G-ZGYDJ8Q2M1"
});

export const auth = app.auth();

export default app;
