import React from 'react';
import { Button } from 'antd';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import PinterestLayout from './PinterestLayout';
import ContainerCard from './ContainerCard';
import TeamService from '../service/team.service';

export default function Dashboard() {
  const { logout, refreshToken } = useAuth();
  const navigate = useNavigate();

  async function handleLogout() {
    await logout();
    navigate('/login');
  }

  async function getTeams() {
    await TeamService.getTeams();
  }

  // TODO:　撈資料 顯示卡片
  return <div>dashboard
    <Button type="primary" onClick={handleLogout}>Logout</Button>
    <Button type="primary" onClick={getTeams}>GetTeams</Button>
    <Button type="primary" onClick={refreshToken}>Refresh Token</Button>
    <PinterestLayout>
      <ContainerCard size="small"/>
      <ContainerCard size="small"/>
      <ContainerCard size="large"/>
      <ContainerCard size="large"/>
      <ContainerCard size="medium"/>
      <ContainerCard size="medium"/>
      <ContainerCard size="large"/>
      <ContainerCard size="small"/>
      <ContainerCard size="small"/>
      <ContainerCard size="medium"/>
    </PinterestLayout>

  </div>;
}
