import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { useLayoutEffect, useRef } from 'react';
import useScript from 'react-use-scripts';
import { auth } from '../firebase';

const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: '/',
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
};

function FirebaseAuthUI() {
  const firebaseuiElement = useRef<HTMLDivElement | null>(null);
  const languageCode = 'zh_tw';

  const { ready } = useScript({
    src: `https://www.gstatic.com/firebasejs/ui/6.0.0/firebase-ui-auth__${languageCode}.js`,
  });

  useLayoutEffect(() => {
    if (ready && firebaseuiElement.current) {
      (window as any).firebase = firebase;
      const firebaseuiUMD: typeof firebaseui = (window as any).firebaseui;
      const ui = new firebaseuiUMD.auth.AuthUI(auth);
      ui.start(firebaseuiElement.current, uiConfig);
    }
  }, [ready]);

  return <div ref={firebaseuiElement} id="firebaseui-auth-container"></div>;
}

export default FirebaseAuthUI;
