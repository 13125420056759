import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import PageLoading from './PageLoading';

interface Props {
  component: React.ComponentType;
  path?: string;
}

export const PrivateRoute: React.FC<Props> = ({ component: Component }) => {
  const { currentUser, isAuthReady } = useAuth();

  if (!isAuthReady) {
    return <PageLoading/>;
  }

  if (currentUser) {
    return <Component/>;
  }

  return <Navigate to={"/login"}/>;
};
