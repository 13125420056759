import axiosInstance from '../lib/axios.instance';

interface Team {

}

export default class TeamService {
  static async getTeams(): Promise<Team[]> {
    const data = await axiosInstance.get('/api/v1/team/list');
    return data.data;
  }
}

