import axios from 'axios';
import { auth } from '../firebase';

let backendURL = '';
if (process.env.NODE_ENV !== 'production') {
  backendURL = 'http://localhost:8080';
}

const axiosInstance = axios.create({
  baseURL: backendURL,
});

axiosInstance.interceptors.request.use(function (config) {
  //@ts-ignore
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('fbToken')}`;
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(res => res, async (error) => {
  const data = error?.response?.data;

  // 如果token過期，嘗試取得refresh token再呼叫一次api
  if (data?.code === "E0000002") {
    if (auth.currentUser) {
      await auth.currentUser.getIdToken(true);
      error.config._retryTimes = error?.config?._retryTimes || 1;
      if (error.config._retryTimes <= 1) {
        error.config._retryTimes += 1;
        return axiosInstance.request(error.config);
      }
    }
  }
  return Promise.reject(error);
});

export default axiosInstance;
