import FirebaseAuthUI from './FirebaseAuthUI';
import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import PageLoading from './PageLoading';
import { Card } from 'antd';

export default function Login() {
  const { currentUser, isAuthReady } = useAuth();

  if (!isAuthReady) {
    return <PageLoading/>;
  }

  if (currentUser) {
    return <Navigate to="/"/>;
  }

  // TODO: 置中 加上navigator 登入驗證
  return <div className="full-center">
    <Card title="登入打球！" style={{ textAlign: 'center' }}>
      <FirebaseAuthUI/>
    </Card>
  </div>;
}
