import React from 'react';
import './App.css';
import { AuthProvider } from './contexts/AuthContext';
import { Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import { PrivateRoute } from './components/PrivateRoute';
import Dashboard from './components/Dashboard';

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="login" element={<Login/>}/>
        <Route path="" element={<PrivateRoute component={Dashboard}/>}/>
      </Routes>
    </AuthProvider>
  );
}

export default App;
